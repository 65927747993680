import ValidService from "../../service/ValidService";

let model = {};

// dữ liệu form
model.dataForm = {
    "id": undefined,
    "level": undefined,
    "threshold_name": undefined,
    "lower_threshold": undefined,
    "higher_threshold": undefined,
    "note": undefined,
    "disabled": undefined,
};

model.roles = [
    {
        value: "View",
        label: "View"
    },
    {
        value: "Edit",
        label: "Edit"
    }
]

model.tableRules = {
    lengthMenu: [10, 20, 50, 100],
    allowPaging: true,
    allowSorting: true,
    allowSelect: false,
    showFormSearch: false,
    showUrl: true,
    total: 0,
    page: 1,
    limit: 20,
    offset: 0,
    sort: "name",
    defaultSort: ["name", "ascending"],
    fields: "id,level,threshold_name,lower_threshold,higher_threshold,note,disabled",
    filters: "",
    dataSearch: {
        value: {
            level: "",
            threshold_name: "",
        },
        valid: {
            // name: [ValidService.checkNameAllowVN],
            level: [ValidService.checkNumber],
            threshold_name: [ValidService.checkNameAllowVN],
        },
        operator: {
            level: "=",
            threshold_name: ":regex_i:",
            
        },
    },
};

export default model;
